import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Lottie from "lottie-react";
import page404Animation from "../lottie/404.json";

export default function NotFoundPage() {


    return (
        <Layout>
            <Seo title="404: Not found"/>
            <Lottie animationData={page404Animation} loop={true}/>
        </Layout>
    )
}
